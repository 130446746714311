<template>
    <div>
        <base-form
            ref="form"
            v-model="form"
            :api="api"
            :mapper="mapper"
            :entity-id="entityId"
            :title="title"
            :disabled="disabled"
            :disable-delete-button="true"
            @created="created($event)"
            @updated="updated($event)"
            @valid="baseFormValid($event)"
        >
            <v-row class="pt-2 grey-bg">
                <v-col cols="12" sm="12" class="py-0">
                    <v-card elevation="0" class="mb-4">
                        <v-card-text>
                            <form-one-col-row>
                                <template>
                                    <text-input
                                        v-model="form.name"
                                        v-validate="
                                            'required|min:2|max:50|space'
                                        "
                                        name="name"
                                        :error-messages="errors.collect('name')"
                                        :data-vv-as="$t('custom_export.name')"
                                        :label="$t('custom_export.name')"
                                        autocomplete="off"
                                    ></text-input>
                                </template>
                            </form-one-col-row>
                            <form-one-col-row>
                                <template>
                                    <entity-select-input
                                        v-model="form.category"
                                        v-validate.immediate="'required'"
                                        label="labels.category"
                                        name="category"
                                        :entity="
                                            selectApiClientType.CUSTOM_EXPORT_CATEGORY
                                        "
                                        :data-vv-as="$t('labels.category')"
                                        :error-messages="
                                            errors.collect('category')
                                        "
                                        autocomplete
                                    ></entity-select-input>
                                </template>
                            </form-one-col-row>
                            <form-one-col-row>
                                <template>
                                    <textarea-input
                                        v-model="form.description"
                                        name="description"
                                        label="labels.description"
                                        class="mt-4"
                                        :error-messages="
                                            errors.collect('ingredients')
                                        "
                                        :data-vv-as="$t('labels.ingredients')"
                                    ></textarea-input>
                                </template>
                            </form-one-col-row>
                            <form-one-col-row>
                                <template>
                                    <checkbox-input
                                        v-model="form.isUpdate"
                                        label="labels.update_query"
                                        name="isUpdate"
                                    ></checkbox-input>
                                </template>
                            </form-one-col-row>
                            <form-one-col-row v-if="entityId">
                                <template>
                                    <div>
                                        <a
                                            target="_blank"
                                            :href="`${baseURL}/public/custom-export?token=${form.token}`"
                                        >
                                            {{
                                                $t(
                                                    'custom_export.download_link'
                                                )
                                            }}
                                        </a>
                                    </div>
                                </template>
                            </form-one-col-row>
                            <form-one-col-row>
                                <template>
                                    <custom-export-parameters
                                        v-model="form.customExportParameters"
                                    ></custom-export-parameters>
                                </template>
                            </form-one-col-row>
                            <form-one-col-row>
                                <template>
                                    <textarea-input
                                        v-model="form.query"
                                        v-validate="'required'"
                                        name="query"
                                        label="custom_export.query"
                                        :full-width="false"
                                        :error-messages="
                                            errors.collect('query')
                                        "
                                        :data-vv-as="$t('custom_export.query')"
                                    ></textarea-input>
                                </template>
                            </form-one-col-row>
                        </v-card-text>
                    </v-card>
                    <div></div>
                </v-col>
            </v-row>

            <portal to="footer">
                <v-btn
                    color="grey"
                    outlined
                    style="margin-right: 0.75rem"
                    @click="backtolist"
                >
                    {{ $t('actions.back-to-list') }}
                </v-btn>
            </portal>
        </base-form>
    </div>
</template>

<script>
import routeType from '@/router/routeType'
import ApiClientType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import FormMixin from '@/components/mixins/FormMixin'
import AuthMixin from '@/components/mixins/AuthMixin'
import TextareaInput from '@/components/form/Inputs/TextareaInput'
import Config from '@/config.loader'
import CustomExportParameters from '@/components/pages/customExport/CustomExportParameters'

export default {
    components: {
        TextareaInput,
        CustomExportParameters,
    },
    mixins: [FormMixin, AuthMixin],
    data() {
        return {
            api: ApiClientType.CUSTOM_EXPORT,
            mapper: MapperType.CUSTOM_EXPORT_FORM,
            form: {},
            entity: 'custom_export.query',
            editRoute: routeType.CUSTOM_EXPORT_EDIT,
            listRoute: routeType.CUSTOM_EXPORT_LIST,
            isValid: false,
            baseURL: Config.getConfigValue('VUE_APP_ADMIN_API_BASE'),
        }
    },
    computed: {
        title: function () {
            return this.$t('menu.custom_export')
        },
    },
    methods: {
        baseFormValid(value) {
            this.isValid = value
        },
        backtolist() {
            this.$router.back()
        },
    },
}
</script>
